import React, { useContext } from "react";
import Meta from "../components/Meta/Meta.jsx";
import ThankYou from "../components/ThankYou/ThankYou.jsx";
import { DataContext } from "../contexts/DataContext.jsx";

const ThankYouPage = () => {
  const { pageLabels } = useContext(DataContext);

  return (
    <>
      <Meta />
      <div style={{ padding: "20vh 0" }}>
        <ThankYou
          title={pageLabels.title}
          subtitle={pageLabels.subtitle}
          paragraph={pageLabels.paragraph}
        />
      </div>
    </>
  );
};

export default ThankYouPage;
