import styled, { css } from "styled-components";
import { Rem, Colors } from "../../commons/Theme";

export const StyledContainer = styled.div`
  display: flex;
  align-content: space-around;
  justify-content: center;
  align-items: center;
`;


export const StyledBox = styled.div`
  width: 90%;
  box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
  border-radius: ${Rem(5)};
  text-align: center;
  margin: ${Rem(10)} 0;
  padding:${Rem(10)} 0;
`;