import React, { useEffect } from "react";
import {
  StyledContainer, StyledBox,
} from "./style";
import {
  StyledParagraph, StyledSubTitle, StyledTitleHero,
} from "../globalstyle";

const ThankYou = (props) => {

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        event: "B2BContact",
      });
    }
  }, [])

  return (
    <StyledContainer>
      <StyledBox>
        <StyledTitleHero>
          {props.title}
        </StyledTitleHero>
        <StyledSubTitle>
          {props.subtitle}
        </StyledSubTitle>
        <StyledParagraph>
          {props.paragraph}
        </StyledParagraph>
      </StyledBox>
    </StyledContainer >
  );
};

export default ThankYou;
